// Gerekli modülleri içe aktarın
import React, { useEffect, useRef, useState } from 'react';
import { Button, Card, Row, Typography, Modal, Input, List, Tooltip } from 'antd';
import {
    PlayCircleOutlined,
    SendOutlined,
    AudioOutlined,
    SoundOutlined,
} from '@ant-design/icons';
import AIAsistan from '../../assets/Dashboard/ai-assistant.svg';
import UserService from '../../services/UserService';
import ChatService from '../../services/ChatService';

const formatDateTime = () => {
    const now = new Date();
    return now.toLocaleString('tr-TR', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
    });
};

interface Message {
    question: string;
    fullAnswer: string;
    displayedAnswer: string;
    timestamp: string;
    isTyping: boolean;
    isSpeaking: boolean;
}

const AIAsistantCard: React.FC = () => {
    const { Title } = Typography;
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [AIChatCredit, setAIChatCredit] = useState(0);
    const [messages, setMessages] = useState<Message[]>([]);
    const [input, setInput] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [isListening, setIsListening] = useState(false);
    const [voices, setVoices] = useState<SpeechSynthesisVoice[]>([]);
    const [voicesLoaded, setVoicesLoaded] = useState(false); // Seslerin yüklendiğini takip etmek için

    const messagesEndRef = useRef<HTMLDivElement>(null);
    const recognitionRef = useRef<any>(null);
    const utteranceRef = useRef<SpeechSynthesisUtterance | null>(null);

    const handleStart = () => setIsModalOpen(true);
    const handleClose = () => {
        setIsModalOpen(false);
        if (isListening) {
            handleListen(); // Mikrofon açıksa kapat
        }
        if (utteranceRef.current) {
            window.speechSynthesis.cancel(); // Okumayı durdur
        }
    };

    const handleSend = async () => {
        if (!input.trim() || isLoading) return;

        setIsLoading(true);

        const newMessage: Message = {
            question: input,
            fullAnswer: '',
            displayedAnswer: '',
            timestamp: formatDateTime(),
            isTyping: true,
            isSpeaking: false,
        };

        setMessages((prevMessages) => [...prevMessages, newMessage]);
        setInput('');

        try {
            // API'ye soruyu gönder
            const response = await ChatService.create({ question: input });
            const answer = response.data.answer;

            setAIChatCredit((prev) => Math.max(prev - answer.length, 0)); // Krediyi azalt

            // Mesajı güncelle ve yazma efektini başlat
            setMessages((prevMessages) =>
                prevMessages.map((msg) =>
                    msg.timestamp === newMessage.timestamp && msg.question === newMessage.question
                        ? { ...msg, fullAnswer: answer }
                        : msg
                )
            );

            startTypingEffect(newMessage, answer);
        } catch (error) {
            console.error('Hata oluştu:', error);
            setIsLoading(false);
        }
    };

    const startTypingEffect = (message: Message, text: string) => {
        let currentIndex = 0;
        const typingSpeed = 50; // Milisaniye cinsinden yazma hızı

        // Mesaj objesini güncelle ve fullAnswer'ı ekle
        const updatedMessage = {
            ...message,
            fullAnswer: text,
        };

        setMessages((prevMessages) =>
            prevMessages.map((msg) =>
                msg.timestamp === message.timestamp && msg.question === message.question
                    ? updatedMessage
                    : msg
            )
        );

        // Yazma işlemi başlarken metni okumaya başla
        if (voicesLoaded) {
            handleSpeak(updatedMessage);
        } else {
            // Sesler henüz yüklenmediyse bekle ve sonra oku
            const interval = setInterval(() => {
                if (voicesLoaded) {
                    handleSpeak(updatedMessage);
                    clearInterval(interval);
                }
            }, 500);
        }

        const type = () => {
            if (currentIndex <= text.length) {
                const currentText = text.substring(0, currentIndex);
                setMessages((prevMessages) =>
                    prevMessages.map((msg) =>
                        msg.timestamp === message.timestamp && msg.question === message.question
                            ? { ...msg, displayedAnswer: currentText }
                            : msg
                    )
                );

                currentIndex++;
                scrollToBottom(); // Mesaj yazılırken otomatik kaydır
                setTimeout(type, typingSpeed);
            } else {
                // Yazma işlemi tamamlandı
                setMessages((prevMessages) =>
                    prevMessages.map((msg) =>
                        msg.timestamp === message.timestamp && msg.question === message.question
                            ? { ...msg, isTyping: false, displayedAnswer: text }
                            : msg
                    )
                );
                setIsLoading(false);
            }
        };

        type();
    };

    const userGet = () => {
        UserService.getMe()
            .then((response: any) => {
                localStorage.setItem(
                    'liveLessonCredit',
                    response.data.liveLessonCredit
                );
                localStorage.setItem('aiChatCredit', response.data.aiChatCredit);
                setAIChatCredit(response.data.aiChatCredit??0);
            })
            .catch((e: Error) => {
                console.error('Kullanıcı bilgileri alınamadı:', e);
            });
    };

    const scrollToBottom = () => {
        if (messagesEndRef.current) {
            messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    };

    useEffect(() => {
        if (isModalOpen) {
            userGet();
        }
    }, [isModalOpen]);

    // Sesli giriş için tanımlamalar
    const initializeRecognition = () => {
        let recognition: any = null;

        if ('webkitSpeechRecognition' in window || 'SpeechRecognition' in window) {
            const SpeechRecognition: any =
                (window as any).SpeechRecognition ||
                (window as any).webkitSpeechRecognition;
            recognition = new SpeechRecognition();
            recognition.lang = 'tr-TR';
            recognition.continuous = true; // Sürekli dinleme açık
            recognition.interimResults = false; // Geçici sonuçlar kapalı

            recognition.onresult = (event: any) => {
                let transcript = '';
                for (let i = event.resultIndex; i < event.results.length; ++i) {
                    transcript += event.results[i][0].transcript;
                }
                setInput((prevInput) => prevInput + ' ' + transcript.trim());
            };

            recognition.onend = () => {
                if (isListening) {
                    recognition.start(); // Tanımayı hemen yeniden başlat
                }
            };

            recognition.onerror = (event: any) => {
                console.error('Ses tanıma hatası:', event.error);
                if (isListening) {
                    recognition.start(); // Hata durumunda tanımayı yeniden başlat
                }
            };
        }

        recognitionRef.current = recognition;
    };

    const handleListen = () => {
        if (!recognitionRef.current) {
            initializeRecognition();
        }

        const recognition = recognitionRef.current;

        if (!recognition) {
            console.error('Bu tarayıcı ses tanımayı desteklemiyor.');
            return;
        }

        if (isListening) {
            recognition.stop();
            setIsListening(false);
        } else {
            recognition.start();
            setIsListening(true);
        }
    };

    // Metni sese dönüştürme için tanımlamalar
    useEffect(() => {
        const synth = window.speechSynthesis;

        const loadVoices = () => {
            const voicesList = synth.getVoices();
            if (voicesList.length !== 0) {
                setVoices(voicesList);
                setVoicesLoaded(true);
            }
        };

        if (synth.onvoiceschanged !== undefined) {
            synth.onvoiceschanged = loadVoices;
        }

        // Bazı tarayıcılarda getVoices hemen sonuç döndürmeyebilir, bu yüzden küçük bir gecikme ekleyelim
        setTimeout(() => {
            loadVoices();
        }, 50);
    }, []);

    const handleSpeak = (message: Message) => {
        // Eğer başka bir okuma yapılıyorsa durdur
        if (utteranceRef.current) {
            window.speechSynthesis.cancel();
            setMessages((prev) =>
                prev.map((msg) =>
                    msg.timestamp === message.timestamp && msg.question === message.question
                        ? { ...msg, isSpeaking: false }
                        : msg
                )
            );
            utteranceRef.current = null;

            // Aynı mesaja tekrar basılmışsa durdur ve geri dön
            if (message.isSpeaking) {
                return;
            }
        } else {
            // Eğer başka bir mesaj okunuyorsa, onu durdur
            window.speechSynthesis.cancel();
            setMessages((prev) =>
                prev.map((msg) =>
                    msg.isSpeaking
                        ? { ...msg, isSpeaking: false }
                        : msg
                )
            );
        }

        // Yeni okuma başlat
        const utterance = new SpeechSynthesisUtterance(message.fullAnswer);
        utterance.lang = 'tr-TR';
        const turkishVoices = voices.filter((voice) =>
            voice.lang.startsWith('tr')
        );
        if (turkishVoices.length > 0) {
            utterance.voice = turkishVoices[0];
        }
        utterance.onend = () => {
            setMessages((prev) =>
                prev.map((msg) =>
                    msg.timestamp === message.timestamp && msg.question === message.question
                        ? { ...msg, isSpeaking: false }
                        : msg
                )
            );
            utteranceRef.current = null;
        };
        window.speechSynthesis.speak(utterance);
        utteranceRef.current = utterance;

        setMessages((prev) =>
            prev.map((msg) =>
                msg.timestamp === message.timestamp && msg.question === message.question
                    ? { ...msg, isSpeaking: true }
                    : msg
            )
        );
    };

    return (
        <>
            <Card className="ai-assistant-card" bordered={false}>
                <Title className="m-0" level={5}>
                    AI Asistan
                </Title>
                <img className="ai-assistant-image" src={AIAsistan} alt="AI Asistan" />
                <Row justify="center">
                    <Button
                        type="primary"
                        icon={<PlayCircleOutlined />}
                        size="large"
                        onClick={handleStart}
                    >
                        Başla
                    </Button>
                </Row>
            </Card>

            <Modal
                className="ai-assistant-card"
                title={`AI Asistan - ${AIChatCredit} kredi`}
                open={isModalOpen}
                onCancel={handleClose}
                footer={null}
                width={600}
            >
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        height: '500px',
                        overflowY: 'auto',
                    }}
                >
                    <List
                        dataSource={messages}
                        renderItem={(item) => (
                            <>
                                <List.Item className="chat-question-container">
                                    <Tooltip title={item.timestamp}>
                                        <div className="chat-question">{item.question}</div>
                                    </Tooltip>
                                </List.Item>
                                <List.Item className="chat-answer-container">
                                    <div className="chat-answer" style={{ display: 'flex', alignItems: 'flex-start' }}>
                                        <div style={{ flex: 1, paddingRight: '24px' }}>
                                            {item.displayedAnswer !== '' ? item.displayedAnswer : '...'}
                                        </div>
                                        {!item.isTyping && (
                                            <SoundOutlined
                                                style={{
                                                    fontSize: '18px',
                                                    cursor: 'pointer',
                                                    color: item.isSpeaking ? 'red' : 'inherit',
                                                }}
                                                onClick={() => handleSpeak(item)}
                                            />
                                        )}
                                    </div>
                                </List.Item>
                            </>
                        )}
                    />
                    <div ref={messagesEndRef} />
                </div>

                <Input.Group compact>
                    <Input
                        style={{ width: 'calc(100% - 80px)' }}
                        placeholder="Sorunuzu yazın veya konuşun..."
                        value={input}
                        onChange={(e) => setInput(e.target.value)}
                        onPressEnter={handleSend}
                        disabled={AIChatCredit <= 0 || isLoading}
                    />
                    <Button
                        icon={<AudioOutlined />}
                        onClick={handleListen}
                        disabled={AIChatCredit <= 0 || isLoading}
                        style={{
                            backgroundColor: isListening ? 'red' : undefined,
                            color: isListening ? 'white' : undefined,
                            transform: isListening ? 'scale(1.2)' : undefined,
                            transition: 'transform 0.2s',
                        }}
                    />
                    <Button
                        type="primary"
                        icon={<SendOutlined />}
                        onClick={handleSend}
                        disabled={AIChatCredit <= 0 || isLoading || !input.trim()}
                    />
                </Input.Group>
            </Modal>
        </>
    );
};

export default AIAsistantCard;